<template>
    <div class="relative">
        <div v-if="disabled" class="absolute inset-0 flex justify-center items-center text-3xl text-black bg-white opacity-80 z-10 rounded-lg">
            To browse invoices, first select a payment
        </div>

        <div class="card-tail flex justify-between border-b pb-3 mb-2">
            <div class="flex">
                <ClientsQuickSearch @selected="searchParametersChanged" />
                <CurrencySelect @change="searchParametersChanged" />
            </div>
            <div class="flex flex-row">
                <el-date-picker v-model="dateRange" class="mr-3" @change="datePickerChange" type="daterange" range-separator="To" start-placeholder="Startdatum" end-placeholder="Slutdatum" />
                <SearchBar @change="searchInputChange" />
            </div>
        </div>

        <div class="card-tail">
            <AvstamningAllInvoicesTable :invoices-data="invoicesData" @matchInvoice="openModalMatchInvoice" />
            <Pagination @change="updateUrlAndGetAll" :total-amount="totalInvoicesNumber" :page-number-prop="pageNumber" />
        </div>
    </div>
</template>
<script>
import moment from "moment";
import queryString from "query-string";
import Api from "../avstamning.api.js";

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        ClientsQuickSearch: () => import("./ClientsQuickSearch.vue"),
        Pagination: () => import("./Pagination.vue"),
        AvstamningAllInvoicesTable: () => import("./AvstamningAllInvoicesTable.vue"),
        SearchBar: () => import("./SearchBar.vue"),
        CurrencySelect: () => import("./CurrencySelect.vue"),
    },

    data() {
        return {
            invoicesData: [],
            totalInvoicesNumber: 0,
            pageNumber: 1,
            pageSize: 15,
            clientId: "",
            search: "",
            currency: "",
            dateRange: [],
        };
    },

    created() {
        this.updateUrlAndGetAll();
    },

    methods: {
        datePickerChange() {
            const query = queryString.parse(window.location.search);
            query.dateFrom = this.dateRange ? moment(this.dateRange[0]).format("YYYY-MM-DD") : "";
            query.dateTo = this.dateRange ? moment(this.dateRange[1]).format("YYYY-MM-DD") : "";
            this.$router.replace({ query }).catch(() => {});
            this.getAll();
        },

        searchInputChange() {
            const { search } = queryString.parse(window.location.search, { sort: false });
            this.search = search || "";
            this.getAll();
        },

        updateUrlAndGetAll() {
            const { clientId, pageNumber, currency } = queryString.parse(window.location.search, { sort: false });
            this.clientId = clientId || "";
            this.pageNumber = pageNumber || this.pageNumber;
            this.currency = currency || "";
            this.getAll();
        },

        async getAll() {
            this.$waiting.start("loading");
            const dateFrom = this.dateRange ? this.dateRange[0] : "";
            const dateTo = this.dateRange ? this.dateRange[1] : "";
            const response = await Api.getAllInvoices(this.pageNumber, this.pageSize, this.clientId, this.search, this.currency, dateFrom, dateTo);
            this.invoicesData = response.data;
            this.totalInvoicesNumber = parseInt(response.headers["x-pagination-totalcount"]);
            this.$waiting.end("loading");
        },

        searchParametersChanged() {
            this.updateUrlAndGetAll();
        },

        openModalMatchInvoice(id) {
            this.$emit("openModalMatchInvoice", id);
        },

        currencyChanged() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.currency = this.currency;
            this.$router.replace({ query }).catch(() => {});
        },
    },
};
</script>
